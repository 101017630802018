/* @import url(http://fonts.googleapis.com/earlyaccess/droidarabickufi.css); */
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

body {
  margin: 0;
  font-family: "Almarai", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background: linear-gradient(197.4deg, #F1F6FF 0%, #D1E6FF 100%);
  overflow-x: hidden;

}

code {
  font-family: "Almarai", sans-serif;
  font-weight: 400;
  font-style: normal;
}



.sharedColor {
  color: #3D6493;
  font-weight: 800;
}

.sharedInfoColor {
  color: #4770B9 !important;
}



.sharedButtonColor {
  background-color: #5480CD !important;
  color: white !important;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
  padding: 10px !important;
}



.playBtn {
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}

.info {
  font-weight: 300;
}

.sharedInfo {
  margin-top: 120px;
}


.comment {
  background: linear-gradient(260.18deg, #D1E6FF 0%, #F8FBFF 99.56%);
  border: 1px solid #8CC0FF;
  padding: 20px;
  border-radius: 5px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 50% !important;
  margin: auto;
}

.serviceCard {
  background: linear-gradient(90deg, rgba(248, 251, 255, 0.8) 0%, rgba(218, 236, 255, 0.8) 100%);
  border: 1px solid #8CC0FF;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  height: 25vh;
}

.slick-next:before, .slick-prev:before {
  content: ' ' !important;
}

.partner {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid #8CC0FF;
  background: linear-gradient(90deg, rgba(248, 251, 255, 0.8) 0%, rgba(218, 236, 255, 0.8) 100%) !important;
  margin-bottom: 20px;
}

.partner img {
  width: 100%;
  object-fit: cover;
}

.cardProduct {
  background: linear-gradient(44.7deg, #F0F7FF 0%, #DDEDFF 73.14%);

}

.cardOwner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  /* Adjust the height as needed */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border: 1px solid #8CC0FF;
  background: linear-gradient(260.18deg, #D1E6FF 0%, #F8FBFF 99.56%);
  width: 80%;
  margin: auto;
}


.breadCrumb {
  background: linear-gradient(90.14deg, #527ECA -0.69%, #3D65AB 100%);
  padding: 20px;
  color: white;
}

.service-image-container {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-image:hover {
  transform: scale(1.05);
}


.orderList li {
  margin-top: 10px;
  color: #0E2245;
}


.sharedBtn {
  background-color: #5480CD !important;
  color: white !important;
  margin-right: 20px;
}

.serviceRequest {
  background: linear-gradient(90deg, #E0EFFF 0%, #F3F9FF 100%);
  border: 1px solid #C1DEFF;
  width: 50%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.orderedList li {
  margin-top: 50px;
}


.common {
  background-color: #5480CD;
  color: white;
  padding: 10px;
  border-radius: 10px;
  width: 50%;
  margin: 10px auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sharedHover:hover {
  background-color: #304976 !important;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
}

/* Modals  */
.modalServicesBody {
  background: linear-gradient(180deg, #D7E9FF 0%, #EDF4FF 100%);
  padding: 15px;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.cancel {
  color: #BF4148 !important;
  background-color: white !important;
  border: 1px solid #BF4148 !important;
  outline: none !important;
}

.save {
  background-color: #4973BC !important;
  color: white !important;
}

@media(max-width:768px) {
  .comment {
    width: 100% !important;
    margin: auto;
  }

  .serviceRequest {

    width: 100%;
  }

  .service-image-container {
    padding-bottom: 66.67%;
    margin: 20px 0;
  }
}
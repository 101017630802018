Footer Design a {
    text-decoration: none;
    color: white;
}

.footer {
    background: linear-gradient(82.4deg, #2A4067 0%, #5480CD 100%);

    padding: 20px;
}

.widget ul {
    list-style-type: none;
}

.widget ul li {
    text-align: right;
}



.ps-list--social .widget-title {
    color: white;
    font-weight: 700;
    margin-bottom: 10px;
}

.ps-list--social li {
    margin-bottom: 15px;
}

.ps-list--social .facebook {
    color: white !important;
    text-decoration: none !important;
    margin-top: 15px !important;
}

.login {
    background: linear-gradient(257.02deg, #6E9DEF 28.68%, #4B75C0 71.32%);
    color: white;
    outline: none;
    border: none;
}

.login:hover {
    background: linear-gradient(257.02deg, #4B75C0 28.68%, #6E9DEF 71.32%);
}

.navbar-toggler {
    margin-top: -33px;
}

.search {
    margin-top: 5px;
}

.serviceTap{
    margin-top: -5px;
}



@media (max-width: 992px) {
    .linkNav{
        margin-bottom: 15px !important;
    }
    .serviceTap{
        margin-bottom: 15px !important;
        margin-right: -15px;
    }
}